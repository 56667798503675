import React, { useState, useEffect, useContext } from 'react';
import { Cart, EmptyCart } from '../components/cart';
import { Summary } from '../components/summary/Summary';
import { Loader } from '../components/shared';
import { IHttpFactory, IHttpFactoryType } from 'core/http';
import { useInjection } from '../../core/ioc';
import { ConfirmCheckoutModal } from '../components/summary/modals';
import { ClearCartModal } from '../components/cart/modals';
import { useUserCart } from './useUserCartHook';
import { ISignalRServiceProviderType, SignalRServiceProvider } from '../../core/signalr';
import { Address } from '../models';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import { UserContext } from '../../core/UserContext';

const CartPage = ({ endPunchoutSessionEndpoint }: { endPunchoutSessionEndpoint : string }) => {
  const httpFactory = useInjection<IHttpFactory>(IHttpFactoryType);
  const signalRServiceProvider = useInjection<SignalRServiceProvider>(ISignalRServiceProviderType);
  const [cart, responseLoading, updateCart, setLoading] = useUserCart();
  const [isCheckoutSucceed, setIsCheckoutSucceed] = useState(false);
  const [isCartReturn, setIsCartReturn] = useState(false);
  const [clearModalOpen, setClearModalOpen] = useState(false);
  const [checkoutModalOpen, setCheckoutModalOpen] = useState(false);
  const [actualTop, setActualTop] = useState(document.getElementsByTagName('html')[0].scrollTop);
  const { t } = useTranslation();
  const appUserInfo = useContext(UserContext);
  const organization_catalog_legacy_mode = appUserInfo.profile?.organization_catalog_legacy_mode === 'true';
  signalRServiceProvider(
    async () => {
      await updateCart();
    },
    async () => {
      setLoading(true);
      setIsCartReturn(true);
    }
  );

  useEffect(() => {
    if (responseLoading === false && cart !== null && actualTop !== 0) {
      document.getElementsByTagName('html')[0].scrollTo(0, actualTop);
    }
  }, [responseLoading, cart, actualTop]);

  const saveActualTop = () => {
    const top = document.getElementsByTagName('html')[0].scrollTop;
    setActualTop(top);
  };

  const uploadCart = async (file: File): Promise<void> => {
    const http = httpFactory.build(true);
    const formData = new FormData();
    formData.append('file', file);
    setLoading(true);

    try {
      await http.post('v1/Orders', formData);
    } catch (error) {
      const errorObject = error as any;
      setLoading(false);
      const title = errorObject.response?.data?.title;
      const validationFailures = errorObject.response?.data?.validationFailures;
      if (validationFailures) {
        toast.error(t(`obk_upload.errors.${validationFailures[0].errorCode}`));
      } else if (title) {
        toast.error(title);
      }
    }
  };

  const clearCart = async (): Promise<void> => {
    const http = httpFactory.build(true);
    setLoading(true);
    await http.delete('v1/Orders');
  };

  const deleteProduct = async (productId: string, isBundle: boolean): Promise<void> => {
    saveActualTop();
    const http = httpFactory.build(true);
    setLoading(true);
    await http.delete(`v1/${isBundle ? 'BundleProducts' : 'OrderProducts'}/${productId}`);
  };

  const updateProductQuantity = async (productId: string, selectedQuantity: number, isBundle: boolean) => {
    saveActualTop();
    const http = httpFactory.build(true);
    setLoading(true);
    await http.put(`/v1/${isBundle ? 'BundleProducts' : 'OrderProducts'}/${productId}`, selectedQuantity, {
      headers: { 'Content-Type': 'application/json' },
    });
  };

  const fileSelectedHandler = (event: React.ChangeEvent<HTMLInputElement>): void => {
    event.stopPropagation();
    event.preventDefault();
    if (event && event.target && event.target.files) {
      const selectedFile = event.target.files[0];
      uploadCart(selectedFile);
    }
  };

  const clearHandler = () => {
    setClearModalOpen(true);
  };
  const clear = () => {
    clearCart().then(() => {
      setClearModalOpen(false);
    });
  };

  const checkoutHandler = () => {
    setCheckoutModalOpen(true);
  };

  interface PunchoutResponseModel {
    redirectUrl: string;
  }

  const punchoutHandler = async (sessionReturnUrl: string): Promise<void> => {
    const http = httpFactory.build(true);
    const response = (await http.post(`/v1/Orders/${cart.id}/punchout`, {
      sessionReturnUrl,
    })) as PunchoutResponseModel;
    if (response.redirectUrl != null) {
      console.log(`Redirecting to ${response.redirectUrl}`);
      setTimeout(() => {
        window.location.assign(response.redirectUrl);
      }, 0);
    }
  };

  const inspectHandler = async (): Promise<void> => {
    setTimeout(() => {
      window.location.assign(endPunchoutSessionEndpoint);
    }, 0);
  };

  const checkout = async (
    deliveryDate: string,
    orderNumber: string,
    customerId: string,
    customerNumber: string,
    shippingAddress: Address,
    billingAddress: Address,
  ): Promise<void> => {
    const http = httpFactory.build(true);
    await http.post(`/v1/Orders/${cart.id}/checkout`, {
      deliveryDate,
      orderNumber,
      customerId,
      customerNumber,
      shippingAddress,
      billingAddress,
    });
    setIsCheckoutSucceed(true);
  };

  return (
    <>
      {responseLoading || isCartReturn === true ? (
        <Loader isReturningCart={isCartReturn} message={t('common.return_cart')} />
      ) : cart === null ? (
        <EmptyCart uploadHandler={fileSelectedHandler} useLegacyConfiguratorApp={organization_catalog_legacy_mode ?? false} />
      ) : (
        <>
          <Cart
            items={cart.basketProducts}
            loading={cart === null}
            deleteProduct={deleteProduct}
            updateProductQuantity={updateProductQuantity}
            pconSessionId={cart.pconSessionId}
            publicObkUri={cart.publicObkUri}
            clearHandler={clearHandler}
            useLegacyConfiguratorApp={organization_catalog_legacy_mode ?? false}
          />
          <Summary
            totals={cart.totals}
            orderId={cart.id}
            checkoutHandler={checkoutHandler}
            punchoutHandler={punchoutHandler}
            inspectHandler={inspectHandler}
          />
          <ClearCartModal
            isOpen={clearModalOpen}
            closeHandler={() => setClearModalOpen(false)}
            clearHandler={clear}
          ></ClearCartModal>
        </>
      )}
      <ConfirmCheckoutModal
        isOpen={checkoutModalOpen}
        closeHandler={() => {
          setIsCheckoutSucceed(false);
          setCheckoutModalOpen(false);
        }}
        checkoutHandler={checkout}
        totals={cart?.totals}
        updateCart={updateCart}
        isCheckoutSucceed={isCheckoutSucceed}
      ></ConfirmCheckoutModal>
    </>
  );
};

export { CartPage };
